import recallServices from '../../../../services/supplier/supplier-recall'
import Communicationdetails from '../../../share/communication-view/supplier/communication-details'
import ChangeHistory from '../../../share/communication-view/supplier/change-history'
import SupplierActions from '../../../share/communication-view/supplier/supplier-actions'
import SupplierCustomerActions from '../../../share/communication-view/supplier/supplier-customer-actions'
import SupplierCommunications from '../../../share/communication-view/supplier/supplier-communications'
import router from '../../../../router/paths'
import WorkflowActions from '../../../share/WorkflowActions'
import PaperResponse from '../../../share/communication-view/supplier/paper-response'
import AffectedLocations from '../../../share/communication-view/supplier/affected-locations'
import AffectedCustomers from '../../../share/communication-view/supplier/affected-customers'
import moment from 'moment-timezone'
import { COMMUNICATION_TYPE } from '../../../../misc/commons'
export default {
    props: {
        id: {type: String, default: null},
        providerCommunicationId: {type: Number, default: null},
    },
    components: {
        Communicationdetails,
        ChangeHistory,
        SupplierActions,
        SupplierCommunications,
        WorkflowActions,
        PaperResponse,
        AffectedLocations,
        AffectedCustomers,
        SupplierCustomerActions
    },
    data() {
        return {
            item: null,
            disruptionEditPathName: router.supp_ProductDisruption_EditItem.name,
            tabWorkflow: false,
            disabledDelegate: false,
            disabledDelete: false,
            tabDetailsKey: 0,
            tabDetails: false,
            tabActions: false,
            tabCommunications: false,
            tabHistory: false,
            tabAffectedLocation: false,
            tabPaperResponse: false,
            selectedSendNotification: null,
            showSendNoti: false,
            showPostUpdate: false,
            disruptionUpdate: null,
            nextExpectedUpdate: null,
            dependentDisabledDates: {
                to: new Date()
            },
            showEdit: false,
            showCloseOpen: false,
            showDelete: false,
            unreadMessages: 0,
            communicationType: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            procductDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            recall: COMMUNICATION_TYPE.RECALL
        }
    },
    watch: {
        item(val) {
            if (val.status && val.status == 'OPEN') {
                this.showSendNoti = true
                this.showPostUpdate = true
                this.showEdit = true
                this.showCloseOpen = true
                this.showDelete = false
            } else if (val.status && val.status == 'CLOSED') {
                this.showSendNoti = false
                this.showPostUpdate = false
                this.showEdit = false
                this.showCloseOpen = true
                this.showDelete = false
            } else if (val.status && val.status == 'DRAFT') {
                this.showSendNoti = true
                this.showPostUpdate = true
                this.showEdit = true
                this.showCloseOpen =true
                this.showDelete = true
            }
        },
    },
    computed: {
        sendOptions() {
            if (this.item.status == 'DRAFT' && this.item) {
                return [
                    { text: 'All providers (' + this.item.send_all + ')', value: 'SEND_ALL', disabled: !(this.item.send_all > 0)},
                    { text: 'New providers (' + this.item.send_new + ')', value: 'SEND_NEW' , disabled: !(this.item.send_new > 0)},
                ]

            }
            if (this.item.status != 'DRAFT'  && this.item) {
                return [
                    { text: 'All providers (' + this.item.send_all + ')', value: 'SEND_ALL', disabled: !(this.item.send_all > 0)},
                    { text: 'New providers (' + this.item.send_new + ')', value: 'SEND_NEW' , disabled: !(this.item.send_new > 0)},
                    { text: 'Non-acknowledged providers (' + this.item.acknowledge_providers + ')', value: 'NON_ACKNOWLEDGED_PROVIDERS' , disabled: !(this.item.acknowledge_providers > 0)}
                ]
            }
        },
    },
    created() {
        this.communicationType = COMMUNICATION_TYPE.PRODUCTDISRUPTION
        if (this.$route.query.conId && this.$route.query.conId > 0) {
            this.tabCommunications = true
        } else {
            this.tabDetails = true
        }
        this.getCommunicationSummaryById()
    },
    methods: {
        getCommunicationSummaryById() {
            recallServices.getCommunicationSummaryById(this.id).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'supp_ProdDisruption' })
                } else {
                    this.item = resp.data.d
                    this.unreadMessages = this.item.unreadMessageCount
                    this.dependentDisabledDates.to = this.item.disruptionUpdateDate ? new Date(new Date(this.item.disruptionUpdateDate.split('T')[0]).getFullYear(), new Date(this.item.disruptionUpdateDate.split(' ')[0]).getMonth(), (new Date(this.item.disruptionUpdateDate.split(' ')[0]).getDate())) : new Date(this.item.communicationDate)
                    if(this.item.status == 'CLOSED' || this.item.status == 'DRAFT')
                    {
                        this.disabledDelegate=true
                    }
                    else
                    {
                        this.disabledDelegate=false
                    }
                }
            })
        },
        goToEditDisruption() {
            this.$router.push({
                name: this.disruptionEditPathName,
                params: { id: this.id }
            })
        },
        closePostUpdate() {
            this.$refs.postUpdate.hide()
        },
        closeSendNotification() {
            this.selectedSendNotification = null
            this.$refs.sendNotification.hide()
        },
        sendNotification() {
            if (['SEND_ALL', 'SEND_NEW', 'NON_ACKNOWLEDGED_PROVIDERS'].includes(this.selectedSendNotification)) {
                recallServices.sendNotification(this.id, this.selectedSendNotification, this.communicationType).then(resp => {
                    if (!resp.error) {
                        this.selectedSendNotification = null
                        this.$refs.sendNotification.hide()
                        this.getCommunicationSummaryById()
                        if (this.tabDetails == true) {
                            this.tabDetailsKey += 1
                        }
                        this._showToast('Sent Successfully!')
                    }
                })
            } else {
                this._showToast('Please select an option', {variant: 'danger'})
            }
        },
        async updateDisruption(){
            this.$refs['observer'].validate().then(isValid => {
                if(isValid){
                    let formData = new FormData()
                    formData.append('disruptionUpdate', this.disruptionUpdate ? this.disruptionUpdate.trim() : '')
                    formData.append('nextExpectedUpdate', this.item.nextExpectedUpdate ? moment(this.item.nextExpectedUpdate).format('YYYY/MM/DD') : '')
                    recallServices.updateDisruption(this.id, formData).then(resp => {
                        if (!resp.error) {
                            this.item.nextExpectedUpdate = null
                            this.disruptionUpdate = null
                            this.$refs.postUpdate.hide()
                            this.getCommunicationSummaryById()
                            if (this.tabDetails == true) {
                                this.tabDetailsKey += 1
                            }
                            this._showToast('Updated Successfully!')
                        }
                    })
                }
                else{
                    this._showToast('Disruption Not Updated', {variant: 'danger'})
                }
            })
        },
        onReLoadCommunicationData(){
            this.getCommunicationSummaryById()
        },
        closeOpenDisruption(){
            var isClose
            if (this.item.status == 'CLOSED'){
                isClose = false
            }
            if (['OPEN', 'DRAFT'].includes(this.item.status)){
                isClose = true
            }
            this._showConfirm(isClose == true ? 'Are you sure you want to close the  product disruption?' : 'Are you sure you want to reopen the  product disruption?', {title: isClose == true ? 'Close Product Disruption' : 'Open  Product Disruption', variant: 'warning'}).then(confirm => {
                if (confirm) {
                    recallServices.closeOpenCommunication(this.id).then(resp => {
                        if (!resp.error) {
                            this.getCommunicationSummaryById()
                            isClose == true ? this._showToast(' Product Disruption Closed!') : this._showToast(' Product Disruption Opened!')
                            if (this.tabDetails == true) {
                                this.tabDetailsKey += 1
                            }
                        }
                    })
                }
            })
        },
        deleteCommunication(){
            this._showConfirm('Are you sure you want to delete the product disruption, this cannot be undone?', {title: 'Delete Product Disruption', variant: 'danger'}).then(confirm => {
                if (confirm) {
                    recallServices.deleteCommunication(this.id, COMMUNICATION_TYPE.PRODUCTDISRUPTION).then(resp => {
                        if (!resp.error) {
                            this.$router.push({ name: 'supp_ProdDisruption' })
                            this._showToast('Product Disruption Deleted!')
                        }
                    })
                }
            })
        },

    }
}